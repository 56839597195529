/* General */
:root {
  --grey: #97aabd;
  --charcoal: #314455;
  --charcoal1: #283745;
  --purple: #644e5b;
  --red: #c96567;
  --darkRed: #9e5a63;
  --colorText: #312f2fff;
}

#root {
  margin-top: 5rem;
  position: relative;
  min-height: 100vh;
  color: var(--colorText);
}

.em {
  font-style: italic;
}

.my-link {
  text-decoration: none;
  color: var(--darkRed);
}

.my-link:hover {
  text-decoration: none;
  color: var(--red);
}

.proj-link {
  text-decoration: none;
  color: var(--colorText);
}

.proj-link:hover {
  text-decoration: none;
  color: var(--red);
  transition-duration: 1000ms;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
  cursor: pointer !important;
}

.btn-simple {
  border: 1px solid black !important;
}

.btn-simple:focus {
  text-emphasis: none !important;
  cursor: pointer !important;
}

.btn-simple:hover {
  background-color: var(--grey) !important;
  cursor: pointer !important;
}

.hidden {
  display: none;
}

/* Main */
.main {
  padding-bottom: 15rem;
}

/* Navbar CSS */
.navbar {
  box-shadow: rgb(230, 230, 230) 1px 2px 8px;
  position: fixed !important;
  top: 0 !important;
  width: 100%;
  z-index: 1000;
}

.navbar-brand {
  color: var(--charcoal) !important;
}

.navbar-brand:hover {
  color: var(--charcoal1) !important;
}

.navbar-nav {
  display: inline !important;
}

.nav-item {
  display: inline-block;
  padding: 0 1rem;
  color: black;
}

.nav-item:hover {
  text-decoration: none;
  color: grey;
}

/* Footer CSS */
.footer {
  padding-top: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--charcoal);
  height: 10rem;
}

.footerCol {
  color: white;
  font-size: large;
  padding-bottom: 2rem;
}

.footerIcon {
  height: 2rem !important;
  margin: 0.5rem;
  fill: white;
}

.footerIcon:hover {
  animation: iconShake 1s linear;
}

.footer-list {
  list-style-type: none;
}

.footer-list-link {
  text-decoration: none;
  color: var(--grey);
}

.footer-list-link:hover {
  text-decoration: none;
  color: var(--darkRed);
}

@keyframes iconShake {
  0% {
    transform: rotate(0deg) scale(1.15);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}

.footerIcon:active {
  transform: scale(1);
  transition: transform 0.1s ease-out;
}

.footerEnd {
  text-align: center;
  font-size: 0.75rem;
  background: var(--charcoal1);
  color: white;
}

/* Landing CSS */
.lbp {
  padding-top: 10%;
}

.landing-btn {
  border: 1px solid white;
  border-radius: 15px;
  margin: 1rem;
  padding: 1rem;
  box-shadow: 0.25rem 0.25rem 1rem var(--grey);
}

.landing-btn-text {
  text-align: center;
  font-size: 3rem;
}
/* About Page CSS */
.portrait {
  max-width: 25vw;
  min-width: 15rem;
  height: auto;
  margin-bottom: 3rem;
}

/* Project List CSS */
.projCard-cover {
  border-radius: 15px;
  position: relative;
}

.projCard-cover:hover {
  z-index: 900;
  background-color: var(--grey);
  transition: background-color 0.5s;
}

.projCard-cover-text {
  display: none;
  font-size: 2.5rem;
  color: var(--colorText);
  position: absolute;
  width: 100%;
  line-height: 0;
  top: 50%;
  text-align: center;
}

.projCard-cover:hover .projCard-cover-text {
  display: block;
  transition: background-color 1s ease 5s;
}

.projCard-cover:hover .proj-box {
  opacity: 0.2;
  transition: opacity 0.5s;
}

.proj-box {
  cursor: pointer;
  border: 1px solid white;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0.25rem 0.25rem 1rem var(--grey);
}

.proj-status {
  text-align: right;
  vertical-align: top;
  font-size: normal;
  font-weight: 100;
}

.prog-complete {
  color: var(--grey);
}

.prog-prog {
  color: var(--red);
}

.proj-box-img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  min-height: 10rem;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 100;
}

.proj-box-img:hover {
  cursor: pointer;
}

.proj-box-title {
  text-transform: capitalize;
}

.proj-box-title:hover {
  cursor: pointer !important;
  color: var(--charcoal);
}

/* Project Details CSS */
.pdf-box {
  border-radius: 15px;
  height: 80vh;
  overflow: hidden;
  box-shadow: 0.1rem var(--grey);
}

.pdf-window {
  width: 100%;
  height: 100%;
  border: none;
}

/* Project Vid CSS */


/* Eaton Porter CSS */
.EPL {
  border-radius: 12px;
  width: 100%;
  min-height: 10rem;
  background-size: 100%;
  background-repeat: no-repeat;
}

.epIcon {
  min-height: 2rem;
  max-height: 2rem;
  background-size: 100%;
  background-repeat: no-repeat;
}

.epIconText {
  text-align: center;
  text-emphasis: none !important;
}

.epLink {
  text-emphasis: none !important;
  text-decoration: none !important;
  color: var(--colorText);
}

.epLink:hover {
  text-emphasis: none !important;
  text-decoration: none !important;
  color: var(--darkRed) !important;
  font-weight: 500;
}
